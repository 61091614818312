import { db } from "@/firebase"

import {
  FirebaseService,
  Snapshot,
  Maybe,
  Denormalized
} from "@/services/firebase.service"

import { AudioEntry, AudioCollection } from "../types"
export default class AudioServiceImpl extends FirebaseService {
  copyToClient(oldClientID: string, newClientID: string) {
    return (
      db
        // @ts-ignore
        .auxiliary(oldClientID)
        .ref(`client/${oldClientID}/audio/available`)
        .once("value")
        .then(snapshot => {
          const value: Maybe<Record<string, AudioEntry>> = snapshot.val()

          if (value !== null) {
            const ref = db
              // @ts-ignore
              .auxiliary(newClientID)
              .ref(`client/${newClientID}/audio/available`)
            const entries = Object.values(value)
            return entries.map(entry => ref.push(entry))
          }
        })
    )
  }

  _getDB<T extends AudioCollection>(collection: T, options: Options[T]): any {
    if (collection === AudioCollection.Client) {
      // @ts-ignore
      return db.auxiliary(options?.clientID)
    } else if (collection === AudioCollection.Game) {
      // @ts-ignore
      return db.auxiliary()
    } else {
      return db
    }
  }

  create<T extends AudioCollection>(
    collection: T,
    options: Options[T],
    audio: AudioEntry
  ) {
    const database = this._getDB(collection, options)

    const data = options.userID
      ? {
          ...this.deleteIdentity(audio),
          userID: options.userID
        }
      : this.deleteIdentity(audio)

    const path = this._getPath(collection, options)

    return database.ref(path).push(data)
  }

  update<T extends AudioCollection>(
    collection: T,
    options: Options[T],
    audio: AudioEntry
  ) {
    const database = this._getDB(collection, options)

    return database
      .ref(this._getPath(collection, options))
      .update(this.deleteIdentity(audio))
  }

  delete<T extends AudioCollection>(
    collection: AudioCollection,
    options: Options[T]
  ) {
    const database = this._getDB(collection, options)

    return database.ref(this._getPath(collection, options)).remove()
  }

  copy<T extends AudioCollection>(
    collection: AudioCollection,
    options: Options[T],
    audio: AudioEntry
  ) {
    const payload = { ...audio }

    const database = this._getDB(collection, options)

    if (collection === AudioCollection.User) {
      // @ts-ignore
      payload.userID = options.userID
    }

    return database
      .ref(this._getPath(collection, options))
      .push(this.deleteIdentity(payload))
  }

  private _getPath<T extends AudioCollection>(
    collection: T,
    options: Options[T]
  ) {
    switch (collection) {
      case AudioCollection.User:
        // @ts-expect-error
        return `org/${options.orgID}/audio/${
          options.audioID === undefined ? "" : options.audioID
        }`
      case AudioCollection.Game:
        // @ts-expect-error
        return `org/${options.orgID}/game/${options.gameID}/audio/${
          options.audioID === undefined ? "" : options.audioID
        }`
      case AudioCollection.Client:
        // @ts-expect-error
        return `client/${options.clientID}/audio/available/${
          options.audioID === undefined ? "" : options.audioID
        }`
    }
  }

  subscribe<T extends AudioCollection>(
    collection: AudioCollection,
    options: Options[T],
    subscriber: (audios: AudioEntry[]) => any
  ) {
    const database = this._getDB(collection, options)

    let ref = database.ref(this._getPath(collection, options))

    if (collection === AudioCollection.User) {
      ref = ref.orderByChild("userID").equalTo(options.userID)
    }

    const decorator = snapshot => {
      const value: Maybe<Snapshot<Denormalized<AudioEntry>>> = snapshot.val()
      subscriber(value ? this.normalizeSnapshotToArray(value) : [])
    }

    ref.on("value", decorator)

    return () => {
      ref.off("value", decorator)
    }
  }

  subscribeToLoop(clientID: string, subscriber: (value: boolean) => any) {
    const ref = db
      // @ts-ignore
      .auxiliary(clientID)
      .ref(`client/${clientID}/audio/loopStatus`)

    const decorator = snapshot => {
      const value: Maybe<boolean> = snapshot.val()
      subscriber(value ?? false)
    }

    ref.on("value", decorator)

    return () => {
      ref.off("value", decorator)
    }
  }

  subscribeToCurrent(
    clientID: string,
    subscriber: (value: Maybe<AudioEntry>) => any
  ) {
    // @ts-ignore
    const ref = db.auxiliary(clientID).ref(`client/${clientID}/audio/current`)

    const decorator = snapshot => {
      const value: Maybe<AudioEntry> = snapshot.val()
      console.log(value)
      subscriber(value)
    }

    ref.on("value", decorator)

    return () => {
      ref.off("value", decorator)
    }
  }

  setCurrent(clientID: string, audio: AudioEntry | null) {
    return (
      db
        // @ts-ignore
        .auxiliary(clientID)
        .ref(`client/${clientID}/audio/current`)
        .set(audio)
    )
  }

  setLoop(clientID: string, value: boolean) {
    return (
      db
        // @ts-ignore
        .auxiliary(clientID)
        .ref(`client/${clientID}/audio/loopStatus`)
        .set(value)
    )
  }
}

type Options = {
  [AudioCollection.User]: { orgID: string; userID: string; audioID?: string }
  [AudioCollection.Game]: {
    orgID: string
    userID: string
    gameID: string
    audioID?: string
  }
  [AudioCollection.Client]: {
    clientID: string
    userID: string
    audioID?: string
  }
}
